import {
    useMutation,
    UseMutationResult,
    useQuery,
    UseQueryOptions,
    UseQueryResult,
} from 'react-query'
import qs from 'qs'

import axios from 'api/axios'
import {
    ResponseList,
    ResponseError,
    AccountType,
    ResponseData,
    PostAccountTypeRequest,
    AccountTypeItem,
    AccountTypeLimitation,
} from 'api/types'

export const getAccountTypes = <T>(filters?: {}): Promise<T> =>
    axios.get(
        '/account-types' + (filters ? '?' + qs.stringify(filters, {}) : '')
    )

export const useGetAccountTypes = <R = ResponseList<AccountTypeItem[]>>(
    filters?: {},
    options?: UseQueryOptions<R, ResponseError>
): UseQueryResult<R, ResponseError> =>
    useQuery<R, ResponseError>(
        ['account-types', filters],
        () => getAccountTypes<R>(filters),
        options
    )

export const useGetAccountType = <T = AccountType>(
    id: number,
    options?: UseQueryOptions<ResponseData<T>, ResponseError>
) =>
    useQuery<ResponseData<T>, ResponseError>(
        ['account-types', id],
        () => axios.get(`/account-types/${id}`),
        options
    )

export const useGetAccountTypeLimitations = <T = AccountTypeLimitation[]>(
    options?: UseQueryOptions<ResponseData<T>, ResponseError>
) =>
    useQuery<ResponseData<T>, ResponseError>(
        ['account-type-limitations'],
        () => axios.get('/account-types/limitations'),
        options
    )

export const createAccountType = <R1, R2>(data: R1): Promise<R2> =>
    axios.post('/account-types', data)

export const useCreateAccountType = <
    R1 = PostAccountTypeRequest,
    R2 = ResponseData<AccountType>
>(): UseMutationResult<R2, ResponseError, R1> =>
    useMutation((data) => createAccountType<R1, R2>(data))

export const updateAccountType = <R1, R2>(
    id: number | string,
    data: R1
): Promise<R2> => axios.patch('/account-types/' + id, data)

export const useUpdateAccountType = <
    R1 extends Partial<PostAccountTypeRequest> & { id: number },
    R2 = ResponseData<AccountType>
>(): UseMutationResult<R2, ResponseError, R1> =>
    useMutation((data) => updateAccountType<R1, R2>(data.id, data))

export const deleteAccountType = <R2>(id: number | string): Promise<R2> =>
    axios.delete('/account-types/' + id)

export const useDeleteAccountType = <
    R1 extends { id: number },
    R2 = void
>(): UseMutationResult<R2, ResponseError, R1> =>
    useMutation((data) => deleteAccountType<R2>(data.id))
