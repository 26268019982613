import React from 'react'
import { Link } from 'react-router-dom'
import { Formik, Form } from 'formik'

import { Button, Label } from 'components/ui'

import type { ResetPasswordForm, FormSubmitFn } from 'types'

import { ReactComponent as IconSuccess } from 'assets/icon-success.svg'
import { LockCyanIcon } from '../assets/icons'
import { PasswordField } from './forms'

interface ResetPasswordProps {
    isSuccess: boolean
    token: string
    email: string
    passwordRules?: {
        length: number
        uppercaseLetters?: number
        numbers?: number
        specialCharacters?: number
    }
    handleSubmit: FormSubmitFn<ResetPasswordForm>
}

export default function ResetPassword({
    token,
    email,
    isSuccess,
    handleSubmit,
    passwordRules,
}: ResetPasswordProps) {
    if (isSuccess) {
        return (
            <div className="space-y-6 flex flex-col text-center items-center">
                <IconSuccess />
                <p className="mt-7 text-lg leading-6 font-medium">
                    Hasło zostało ustawione
                </p>
                <p className="mt-4 text-sm leading-5 font-normal text-gray-500">
                    Teraz możesz się zalogować, klikając w poniższy link.
                </p>
                <p className="mt-4">
                    <Link
                        to="/login"
                        className="px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700"
                    >
                        Zaloguj się
                    </Link>
                </p>
            </div>
        )
    }

    return (
        <Formik
            initialValues={{
                email: email || '',
                password: '',
                password_confirmation: '',
                token: token || '',
            }}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting, errors }) => (
                <Form className="space-y-6">
                    {passwordRules && (
                        <div className="text-center">
                            <LockCyanIcon className="mx-auto mb-5" />

                            <div className="text-lg leading-6 font-medium text-gray-900 mb-4">
                                Ustaw nowe hasło do systemu
                            </div>

                            <div className="text-gray-500 text-sm leading-5 font-normal">
                                Hasło powinno mieć długość min.{' '}
                                {passwordRules.length}{' '}
                                {passwordRules.length === 1
                                    ? 'znak'
                                    : passwordRules.length < 5
                                    ? 'znaki'
                                    : 'znaków'}
                                <br />i zawierać przynajmniej:
                                <ul className="flex flex-col items-center mt-1">
                                    {!!passwordRules.uppercaseLetters && (
                                        <li className="inline-flex items-center">
                                            <span className="w-1 h-1 rounded-full inline-block bg-gray-500 mr-2" />
                                            {passwordRules.uppercaseLetters}{' '}
                                            {passwordRules.uppercaseLetters ===
                                            1
                                                ? 'dużą literę'
                                                : passwordRules.uppercaseLetters <
                                                  5
                                                ? 'duże litery'
                                                : 'dużych liter'}
                                        </li>
                                    )}
                                    {!!passwordRules.numbers && (
                                        <li className="inline-flex items-center">
                                            <span className="w-1 h-1 rounded-full inline-block bg-gray-500 mr-2" />
                                            {passwordRules.numbers}{' '}
                                            {passwordRules.numbers === 1
                                                ? 'cyfrę'
                                                : passwordRules.numbers < 5
                                                ? 'cyfry'
                                                : 'cyfr'}
                                        </li>
                                    )}
                                    {!!passwordRules.specialCharacters && (
                                        <li className="inline-flex items-center">
                                            <span className="w-1 h-1 rounded-full inline-block bg-gray-500 mr-2" />
                                            {passwordRules.specialCharacters}{' '}
                                            {passwordRules.specialCharacters ===
                                            1
                                                ? 'znak specjalny'
                                                : passwordRules.specialCharacters <
                                                  5
                                                ? 'znaki specjalne'
                                                : 'znaków specjalnych'}{' '}
                                            (np. !@#$%^&)
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    )}

                    <div>
                        <Label>{email ? 'Nadaj hasło' : 'Nowe hasło'}</Label>
                        <PasswordField
                            type="password"
                            name="password"
                            autoComplete="off"
                        />
                    </div>

                    <div>
                        <Label>Powtórz hasło</Label>
                        <PasswordField
                            type="password"
                            name="password_confirmation"
                            autoComplete="off"
                        />
                    </div>

                    {!!errors.token && (
                        <p className="text-sm text-red-600">{errors.token}</p>
                    )}
                    {!!errors.email && (
                        <p className="text-sm text-red-600">{errors.email}</p>
                    )}

                    <div>
                        <Button
                            variant="primary"
                            type="submit"
                            className="w-full"
                            loading={isSubmitting}
                        >
                            Zapisz i przejdź do logowania
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    )
}
