import {
    useMutation,
    UseMutationResult,
    useQuery,
    UseQueryOptions,
    UseQueryResult,
} from 'react-query'

import axios from 'api/axios'
import type {
    ResponseData,
    ResponseError,
    SecuritySetting,
    PostSecuritySettingsRequest,
} from 'api/types'

export const getSecuritySettings = <T>(): Promise<T> => axios.get('/security')

export const useGetSecuritySettings = <R = ResponseData<SecuritySetting[]>>(
    options?: UseQueryOptions<R, ResponseError>
): UseQueryResult<R, ResponseError> =>
    useQuery<R, ResponseError>(
        ['security'],
        () => getSecuritySettings<R>(),
        options
    )

export const updateSecuritySettings = <R1, R2>(data: R1): Promise<R2> =>
    axios.post('/security', data)

export const useUpdateSecuritySettings = <
    R1 = PostSecuritySettingsRequest,
    R2 = ResponseData<SecuritySetting[]>
>(): UseMutationResult<R2, ResponseError, R1> =>
    useMutation((data) => updateSecuritySettings<R1, R2>(data))
