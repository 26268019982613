import React, { useEffect } from 'react'
import moment from 'moment'
import { useFormikContext } from 'formik'

import { useGetProcedure } from 'api'
import {
    PREFERRED_BEGINNING_HOUR_ITEMS,
    ANESTHESIA_TYPES,
} from 'constants/index'
import { getDurationArray } from 'helpers'
import {
    DropdownField,
    TextAreaField,
    TextField,
    ProcedureField,
    EquipmentField,
    AvailableDatePickerField,
    EmployeePickerField,
} from 'components/forms'
import { Label, Spinner } from 'components/ui'
import DoctorSurgeryFormPatientField from 'components/DoctorSurgeryFormPatientField'
import { AssistantFieldArray } from 'components/ScheduleOperationForm'

import type { SurgeryForm } from 'types'

const DoctorSurgeryFormMain: React.FC<{}> = () => {
    const { setFieldValue, errors } = useFormikContext<SurgeryForm>()

    return (
        <div className="text-gray-700 sm:mb-16">
            <div className="grid grid-cols-1 lg:grid-cols-2 text-gray-700 lg:divide-gray-200 lg:divide-x">
                <div className="col-span-1 lg:pr-8">
                    <div className="text-lg leading-6 font-medium text-gray-900">
                        Informacje o pacjencie
                    </div>
                    <div className="divide-gray-200 divide-y">
                        <section className="pb-8">
                            <div className="mt-6">
                                <DoctorSurgeryFormPatientField />
                            </div>
                        </section>
                        <section className="pb-8">
                            <div className="mt-6">
                                <Label>Rozpoznanie</Label>
                                <TextAreaField name="diagnosis" rows={3} />
                            </div>
                            <div className="mt-6">
                                <Label>Znieczulenie</Label>
                                <DropdownField
                                    placeholder="Wybierz rodzaj znieczulenia"
                                    name="anesthesia_type"
                                    items={ANESTHESIA_TYPES}
                                />
                            </div>
                        </section>
                        <section className="pb-8">
                            <div className="mt-6 text-lg leading-6 font-medium text-gray-900">
                                Sprzęt medyczny
                            </div>
                            <div className="mt-6">
                                <EquipmentField name="equipment" editPrice />
                            </div>
                        </section>
                    </div>
                </div>
                <div className="col-span-1 lg:pl-8">
                    <div className="text-lg leading-6 font-medium text-gray-900">
                        Informacje o zabiegu
                    </div>
                    <div className="divide-gray-200 divide-y">
                        <section className="pb-8">
                            <div className="mt-6">
                                <Label>Operator</Label>
                                <EmployeePickerField
                                    name="doctor"
                                    placeholder="Wyszukaj lekarza"
                                    functions={['doctor function']}
                                    messageEmptyResult="Nie znaleziono lekarza."
                                />
                            </div>
                            <div className="mt-6">
                                <Label>Asysta</Label>
                                <AssistantFieldArray />
                            </div>
                            <div className="mt-6">
                                <div className="grid grid-cols-2 sm:grid-cols-6 gap-6">
                                    <div className="col-span-2">
                                        <Label>Data operacji</Label>
                                        <AvailableDatePickerField
                                            name="estimated_date"
                                            placeholder="DD.MM.RRRR"
                                            disabledBefore={moment().toDate()}
                                        />
                                    </div>
                                    <div className="col-span-2">
                                        <Label>Preferowana pora</Label>
                                        <DropdownField
                                            name="preferred_beginning_hour"
                                            items={
                                                PREFERRED_BEGINNING_HOUR_ITEMS
                                            }
                                        />
                                    </div>
                                    <div className="col-span-2">
                                        <Label>Szacowany czas</Label>
                                        <DropdownField
                                            name="estimated_duration"
                                            items={getDurationArray(12, 15)}
                                        />
                                    </div>
                                </div>
                                <div className="mt-6">
                                    <Label>Operacja</Label>
                                    <ProcedureField
                                        name="_procedure_item"
                                        hasError={!!errors.procedure}
                                        onChange={() => {
                                            setFieldValue('implants', [])
                                        }}
                                        onRemove={() => {
                                            setFieldValue('implants', [])
                                            setFieldValue(
                                                'contains_implants',
                                                false
                                            )
                                        }}
                                        queryFilters={{
                                            active: true,
                                        }}
                                    />
                                </div>
                                <div className="mt-6">
                                    <ProcedureLoader>
                                        {({ procedure }) => (
                                            <ProcedureDependentFields
                                                procedure={procedure}
                                            />
                                        )}
                                    </ProcedureLoader>
                                </div>
                            </div>
                        </section>
                        <section className="pb-8">
                            <div className="mt-6">
                                <Label>Uwagi do zabiegu</Label>
                                <TextAreaField name="description" rows={5} />
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const ProcedureLoader = ({
    children,
}: {
    children: ({
        procedure,
    }: {
        procedure: ReturnType<typeof useGetProcedure>
    }) => JSX.Element
}) => {
    const { values, setFieldValue } = useFormikContext<SurgeryForm>()

    const procedure = useGetProcedure(values._procedure_item?.id, {
        enabled: !!values._procedure_item,
    })

    useEffect(() => {
        if (procedure.isSuccess && !!values._procedure_item) {
            setFieldValue('procedure', procedure.data.data)
        }

        if (!values._procedure_item && !!values.procedure) {
            setFieldValue('procedure', null)
        }
    }, [
        setFieldValue,
        procedure.isSuccess,
        procedure.data,
        values.procedure,
        values._procedure_item,
    ])

    return children({ procedure })
}

export const ProcedureDependentFieldsGrid: React.FC<{
    leftSide?: JSX.Element
    center?: JSX.Element
    rightSide?: JSX.Element
}> = ({ leftSide, center, rightSide }) => {
    return (
        <div className="space-y-4 md:space-y-0 md:grid md:grid-cols-12 md:gap-6">
            <div className="flex flex-col col-span-5">
                <Label>Rodzaj zabiegu</Label>
                {leftSide}
            </div>
            <div className="flex flex-col col-span-5">
                <Label>Kategoria</Label>
                {center}
            </div>
            <div className="flex flex-col col-span-2">
                <Label>Dni&nbsp;hosp.</Label>
                {rightSide}
            </div>
        </div>
    )
}

export const ProcedureTypeDependentField: React.FC<{
    name: string
    procedure: ReturnType<typeof useGetProcedure>
}> = ({ name, procedure }) => {
    const { values, setFieldValue } = useFormikContext<SurgeryForm>()

    useEffect(() => {
        if (!values.procedure) {
            setFieldValue(name, null)
        }

        if (!!values.procedure) {
            setFieldValue(name, values.procedure.procedure_type)
        }
        // eslint-disable-next-line
    }, [values.procedure])

    if (procedure.isLoading) {
        return (
            <div className="flex-1 justify-center items-center">
                <Spinner size="xs" />
            </div>
        )
    }

    return <DropdownField name={name} items={[]} disabled />
}

export const ProcedureCategoryDependentField: React.FC<{
    name: string
    procedure: ReturnType<typeof useGetProcedure>
}> = ({ name, procedure }) => {
    const { values, setFieldValue } = useFormikContext<SurgeryForm>()

    useEffect(() => {
        if (!values.procedure) {
            setFieldValue(name, null)
        }

        if (!!values.procedure) {
            setFieldValue(name, values.procedure.procedure_category)
        }
        // eslint-disable-next-line
    }, [values.procedure])

    if (procedure.isLoading) {
        return (
            <div className="flex-1 justify-center items-center">
                <Spinner size="xs" />
            </div>
        )
    }

    return <DropdownField name={name} items={[]} disabled />
}

export const ProcedureRecoveryDaysDependentField: React.FC<{
    name: string
    procedure: ReturnType<typeof useGetProcedure>
}> = ({ name, procedure }) => {
    const { values, setFieldValue } = useFormikContext<SurgeryForm>()

    useEffect(() => {
        if (values.procedure) {
            setFieldValue(name, values.procedure.recovery_days)
        }

        if (values.procedure === null) {
            setFieldValue(name, '')
        }
        // eslint-disable-next-line
    }, [values.procedure])

    return <TextField name={name} disabled errorMessage={false} />
}

export const ProcedureDependentFields: React.FC<{
    procedure: ReturnType<typeof useGetProcedure>
}> = ({ procedure }) => {
    return (
        <ProcedureDependentFieldsGrid
            leftSide={
                <ProcedureTypeDependentField
                    name="procedure_type"
                    procedure={procedure}
                />
            }
            center={
                <ProcedureCategoryDependentField
                    name="procedure_category"
                    procedure={procedure}
                />
            }
            rightSide={
                <ProcedureRecoveryDaysDependentField
                    name="recovery_days"
                    procedure={procedure}
                />
            }
        />
    )
}

export default DoctorSurgeryFormMain
