import React, { Fragment, useCallback, useMemo, useState } from 'react'
import { Link, Outlet } from 'react-router-dom'
import classNames from 'classnames'
import { Menu, Transition } from '@headlessui/react'
import { MenuAlt2Icon, XIcon } from '@heroicons/react/outline'
import { isBefore } from 'date-fns'

import { LOCAL_STORAGE_SIDEBAR_KEY } from 'constants/index'
import { getCxFromStyles } from 'helpers'
import { useGetUser } from 'api'
import { useModal, usePolicy } from 'hooks'
import NotificationToastContainer from 'containers/NotificationToastContainer'
import NotificationBellContainer from 'containers/NotificationBell'
import Sidebar from 'components/Sidebar'
import Clock from 'components/Clock'
import UserAvatar from 'components/UserAvatar'
import AvatarPlaceholder from 'components/AvatarPlaceholder'
import { SecurityUpdateModal } from 'components/SecurityUpdateModal'

import styles from './MainLayout.module.scss'

import Logo from 'assets/logo-promedheus.png'

const USER_MENU_ITEMS = [
    { name: 'Twój profil', to: '/profile' },
    { name: 'Wyloguj się', to: '/logout' },
]

const cx = getCxFromStyles(styles)

const MainLayout: React.FC = () => {
    const securityUpdateModal = useModal(true)
    const [isSidebarOpen, setSidebarOpen] = useState(
        JSON.parse(
            localStorage.getItem(LOCAL_STORAGE_SIDEBAR_KEY) || 'true'
        ) === true
    )
    const user = useGetUser()
    const {
        user: { isTv, isTablet },
    } = usePolicy()
    const shouldDisplayNavigation = useMemo(
        () => !isTv && !isTablet,
        [isTv, isTablet]
    )

    const userPasswordPolicy = useMemo(() => {
        if (user.data) {
            const passwordPolicy = user.data.data.password_policy

            const getPasswordRuleValue = (name: string) => {
                const value = passwordPolicy?.security_settings.find(
                    (item) => item.name === name
                )?.value

                return !isNaN(Number(value)) ? Number(value) : undefined
            }

            return {
                enabled: passwordPolicy?.enabled || false,
                since: passwordPolicy?.since,
                accepted: passwordPolicy?.accepted || false,
                rules: {
                    length: getPasswordRuleValue('min_password_length') || 0,
                    uppercaseLetters:
                        getPasswordRuleValue('min_uppercase_letters') || 0,
                    numbers: getPasswordRuleValue('min_numbers') || 0,
                    specialCharacters:
                        getPasswordRuleValue('min_special_characters') || 0,
                },
            }
        }
        return null
    }, [user.data])

    const toggle = useCallback(() => {
        const nextState = !isSidebarOpen

        localStorage.setItem(LOCAL_STORAGE_SIDEBAR_KEY, nextState.toString())

        setSidebarOpen(nextState)
    }, [isSidebarOpen])

    if (!shouldDisplayNavigation) {
        return (
            <div className="min-h-screen flex">
                <main className="flex flex-col flex-1">
                    <Outlet />
                </main>
            </div>
        )
    }

    return (
        <div className="min-h-screen flex print:min-h-0">
            <Sidebar isSidebarOpen={isSidebarOpen} />
            <div
                id="main-layout-right-side"
                className={classNames(
                    'flex flex-col flex-1 transition-all print:m-0',
                    {
                        'ml-0 md:ml-12': !isSidebarOpen,
                        'ml-64': isSidebarOpen,
                    }
                )}
            >
                <div
                    id="main-layout-header"
                    className="sticky top-0 z-20 flex-shrink-0 flex h-16 bg-white shadow print:hidden"
                >
                    <div className="flex space-x-4">
                        <button
                            type="button"
                            className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none"
                            onClick={toggle}
                        >
                            {isSidebarOpen && (
                                <span className="sr-only">Close sidebar</span>
                            )}
                            {!isSidebarOpen && (
                                <span className="sr-only">Open sidebar</span>
                            )}
                            {isSidebarOpen && (
                                <XIcon className="h-6 w-6" aria-hidden="true" />
                            )}
                            {!isSidebarOpen && (
                                <MenuAlt2Icon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                />
                            )}
                        </button>
                        <div className={cx('logo')}>
                            <Link to="/">
                                <img src={Logo} alt="Logo Promedheus" />
                            </Link>
                        </div>
                    </div>
                    <div className="flex-1 pr-4 flex justify-between">
                        <div className="flex-1 flex"></div>
                        <div className="flex items-center ml-4 md:ml-6">
                            <span className="text-2xl">
                                <Clock />
                            </span>

                            <NotificationBellContainer />

                            {/* Profile dropdown */}
                            <Menu as="div" className="ml-3 relative">
                                <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                                    <span className="sr-only">Otwórz menu</span>
                                    {user.isSuccess && (
                                        <UserAvatar
                                            variant="avatar"
                                            data={{
                                                ...user.data.data,
                                                role:
                                                    user.data.data.role?.name ||
                                                    '',
                                            }}
                                            size={8}
                                        />
                                    )}
                                    {!user.isSuccess && (
                                        <AvatarPlaceholder size={8} />
                                    )}
                                </Menu.Button>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        {USER_MENU_ITEMS.map((item) => (
                                            <Menu.Item key={item.name}>
                                                {({ active }) => (
                                                    <Link
                                                        to={item.to}
                                                        className={classNames(
                                                            'block px-4 py-2 text-sm text-gray-700',
                                                            {
                                                                'bg-gray-100':
                                                                    active,
                                                            }
                                                        )}
                                                    >
                                                        {item.name}
                                                    </Link>
                                                )}
                                            </Menu.Item>
                                        ))}
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>
                    </div>
                </div>
                <main className="flex flex-col flex-1">
                    <Outlet />
                </main>
            </div>

            {userPasswordPolicy &&
                userPasswordPolicy?.enabled &&
                !userPasswordPolicy?.accepted &&
                userPasswordPolicy.since &&
                isBefore(new Date(), new Date(userPasswordPolicy.since)) && (
                    <SecurityUpdateModal
                        modal={securityUpdateModal}
                        date={userPasswordPolicy.since}
                        passwordRules={userPasswordPolicy.rules}
                    />
                )}

            <NotificationToastContainer />
        </div>
    )
}

export default MainLayout
