import { usePolicy } from 'hooks'
import { PERMISSIONS } from 'constants/index'

export const useSecurityGlobalPolicy = () => {
    const { user, hasPermission } = usePolicy()

    return {
        user,
        canUpdate: hasPermission(PERMISSIONS.SET_GLOBAL_SECURITY_SETTINGS),
        canShow: hasPermission(PERMISSIONS.GET_GLOBAL_SECURITY_SETTINGS),
    }
}
