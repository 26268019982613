import React from 'react'
import { useFormikContext } from 'formik'

const ActionBar: React.FC<{
    leftSide?: (props: { isSubmitting: boolean }) => JSX.Element
    rightSide?: (props: {
        isSubmitting: boolean
        resetForm: () => void
    }) => JSX.Element
}> = ({ leftSide, rightSide }) => {
    const { isSubmitting, resetForm } = useFormikContext()

    return (
        <div className="flex items-center justify-between">
            <div className="flex-1">
                {leftSide ? leftSide({ isSubmitting }) : null}
            </div>
            <div className="flex-1 flex items-center justify-end ml-3">
                {rightSide ? rightSide({ isSubmitting, resetForm }) : null}
            </div>
        </div>
    )
}

export default ActionBar
