import moment from 'moment'

import { API_FORMAT_DATE, OPERATION_REJECT_REASONS } from 'constants/index'
import { formatDateTime } from 'helpers'

import type { OperationItem } from 'api/types'

export function forOperationDate(date: string | Date) {
    const estimatedDate = moment(date)

    function isDZ(date: string | Date) {
        return estimatedDate.isSame(moment(date), 'day')
    }

    function isDW(date: string | Date) {
        return estimatedDate.isSame(moment(date).add(1, 'day'), 'day')
    }

    function isDZorDW(date: string | Date) {
        return isDZ(date) || isDW(date)
    }

    function absoluteDateFor(kind: 'dz' | 'dw' | undefined) {
        if (!kind) {
            return kind
        }

        return kind === 'dz' ? getDZ() : getDW()
    }

    function getDZ() {
        return estimatedDate.format(API_FORMAT_DATE)
    }

    function getDW() {
        return estimatedDate.subtract(1, 'day').format(API_FORMAT_DATE)
    }

    return {
        isDZ,
        isDW,
        isDZorDW,
        absoluteDateFor,
    }
}
export function displayAdmissionDate(
    admissionDate: OperationItem['admission_date'],
    estimatedDate: OperationItem['estimated_date']
): string | null {
    if (!admissionDate) {
        return null
    }

    if (forOperationDate(estimatedDate).isDZ(admissionDate)) {
        return 'DZ ' + moment(admissionDate).format('HH:mm')
    }

    if (forOperationDate(estimatedDate).isDW(admissionDate)) {
        return 'DW ' + moment(admissionDate).format('HH:mm')
    }

    return formatDateTime(admissionDate)
}

export const displayRejectReason = (type?: string, reason?: string) => {
    const reasonType = OPERATION_REJECT_REASONS.find(
        (item) => item.type === type
    )

    return `${reasonType ? reasonType.label : ''}${
        reason && reasonType ? ': ' : ''
    }${reason ? reason : ''}`
}
