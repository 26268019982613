import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { Pagination, Toggle } from 'components/ui'
import EmptyList from 'components/EmptyList'

import { ResponseList, AccountTypeItem } from 'api/types'
import type { ListFilterAction } from 'helpers'
import { useAccountTypePolicy } from '../hooks'

export const SettingsAccountTypeList: React.FC<{
    changeActive: (id: number, active: boolean) => void
    data: ResponseList<AccountTypeItem[]>
    setFilters: React.Dispatch<ListFilterAction>
}> = ({ data, setFilters, changeActive }) => {
    return (
        <div className="bg-white p-2 rounded-lg">
            {data.meta.total > 0 && (
                <Table data={data} changeActive={changeActive} />
            )}
            {data.meta.total > 0 && (
                <Pagination
                    meta={data.meta}
                    handleChange={(page) =>
                        setFilters({ type: 'setPage', payload: page })
                    }
                />
            )}
            {data.meta.total === 0 && (
                <EmptyList
                    headline="Nic tu jeszcze nie ma"
                    message="Zacznij od stworzenia pierwszego typu."
                    to="/settings/security/account-types/add"
                />
            )}
        </div>
    )
}

const Table: React.FC<{
    data: ResponseList<AccountTypeItem[]>
    changeActive: (id: number, active: boolean) => void
}> = ({ data, changeActive }) => {
    const accountTypePolicy = useAccountTypePolicy()

    return (
        <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50 text-xs text-gray-500 uppercase">
                <tr>
                    <th
                        scope="col"
                        className="w-auto px-6 py-3 text-left font-medium tracking-wider"
                    >
                        Typ
                    </th>
                    <th
                        scope="col"
                        className="w-1 px-6 py-3 text-left font-medium tracking-wider whitespace-nowrap"
                    >
                        L. ról
                    </th>
                    <th
                        scope="col"
                        className="w-1 px-6 py-3 text-left font-medium tracking-wider"
                    >
                        Aktywny
                    </th>
                    {accountTypePolicy.canUpdate && (
                        <th
                            scope="col"
                            className="w-1 px-6 py-3 text-left font-medium tracking-wider"
                        >
                            Opcje
                        </th>
                    )}
                </tr>
            </thead>
            <tbody className="text-gray-500 text-sm bg-white divide-y divide-gray-200 leading-5">
                {data.data.map((item, index) => (
                    <tr
                        key={item.id}
                        className={classNames({
                            'bg-gray-50': index % 2,
                        })}
                    >
                        <td className="px-6 py-6">
                            <span className="text-gray-900 font-medium">
                                {item.name}
                            </span>
                        </td>
                        <td className="px-6 py-6">
                            <span className="text-gray-900">
                                {item.roles_count}
                            </span>
                        </td>
                        <td className="px-6 py-6">
                            <Toggle
                                checked={item.active}
                                handleChange={(checked) =>
                                    changeActive(item.id, checked)
                                }
                                disabled={!accountTypePolicy.canUpdate}
                            />
                        </td>
                        {accountTypePolicy.canUpdate && (
                            <td className="px-6 py-1 text-right">
                                <span className="font-normal">
                                    <Link
                                        to={`/settings/security/account-types/${item.id}/edit`}
                                        className="inline-flex items-center px-3 py-2 shadow-sm text-xs leading-4 rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 cursor-pointer"
                                    >
                                        Edycja
                                    </Link>
                                </span>
                            </td>
                        )}
                    </tr>
                ))}
            </tbody>
        </table>
    )
}
