import { useAccountTypePolicy, useSecurityGlobalPolicy } from 'hooks'

import type { MenuItemGate } from './index'

export const SecurityMenuItemGate: MenuItemGate = ({ children }) => {
    const { canIndex } = useAccountTypePolicy()
    const { canShow } = useSecurityGlobalPolicy()

    if (canIndex || canShow) {
        return children
    }

    return null
}
