import React from 'react'
import { useField } from 'formik'

import { useTogglePasswordVisibility } from 'hooks'
import { Label, InputPassword } from 'components/ui'
import ErrorMessage from './ErrorMessage'

export default function PasswordField({
    name,
    autoComplete,
    labelText,
    trailingAddOn,
    errorMessage = true,
    onChange,
    ...props
}: {
    name: string
    autoComplete?: string
    labelText?: string
    trailingAddOn?: string
    errorMessage?: boolean
} & React.InputHTMLAttributes<HTMLInputElement>) {
    const [field, meta] = useField(name)

    const { passwordVisibility, handlePasswordVisibility } =
        useTogglePasswordVisibility(false)

    return (
        <>
            {!!labelText && <Label htmlFor={field.name}>{labelText}</Label>}
            <div className="relative">
                <InputPassword
                    {...props}
                    passwordVisibility={passwordVisibility}
                    type={passwordVisibility ? 'text' : 'password'}
                    autoComplete={autoComplete}
                    hasError={errorMessage && !!meta.error}
                    name={field.name}
                    value={field.value}
                    handleChange={(e) => {
                        if (!e.target.validity.valid) {
                            return false
                        }

                        field.onChange(e)
                        typeof onChange === 'function' && onChange(e)
                    }}
                    onVisibilityChange={handlePasswordVisibility}
                />
            </div>
            {errorMessage && <ErrorMessage name={field.name} />}
        </>
    )
}
