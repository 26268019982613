import { usePolicy } from 'hooks'
import { PERMISSIONS } from 'constants/index'

export const ACCOUNT_TYPE_PERMISSIONS = {
    index: PERMISSIONS.INDEX_ACCOUNT_TYPE,
    show: PERMISSIONS.SHOW_ACCOUNT_TYPE,
    create: PERMISSIONS.CREATE_ACCOUNT_TYPE,
    update: PERMISSIONS.UPDATE_ACCOUNT_TYPE,
    delete: PERMISSIONS.DELETE_ACCOUNT_TYPE,
}

export const useAccountTypePolicy = () => {
    const { user, checkPermissions } = usePolicy()

    return {
        user,
        ...checkPermissions(ACCOUNT_TYPE_PERMISSIONS),
    }
}
