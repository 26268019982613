import React from 'react'

export default function AccountLockContainer() {
    return (
        <div className="flex flex-col items-center text-center">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="text-red-600 w-12 mb-6"
            >
                <path
                    fillRule="evenodd"
                    d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
                    clipRule="evenodd"
                />
            </svg>

            <span className="text-lg leading-6 font-medium text-gray-900 mb-4">
                Blokada konta
            </span>

            <span className="text-sm leading-5 font-normal text-gray-500">
                Ze względów bezpieczeństwa Twoje konto zostało zablokowane.
                Skontaktuj się z administratorem aby odblokować konto.
            </span>
        </div>
    )
}
