import { useState } from 'react'

export const useTogglePasswordVisibility = (initialValue: boolean) => {
    const [passwordVisibility, setPasswordVisibility] = useState(initialValue)

    const handlePasswordVisibility = () => {
        setPasswordVisibility((value) => !value)
    }

    return {
        passwordVisibility,
        handlePasswordVisibility,
    }
}
