import React, { useReducer } from 'react'
import { PlusIcon } from '@heroicons/react/solid'

import { DEFAULT_LIST_PER_PAGE } from 'constants/index'
import { listReducer } from 'helpers'
import { useGetAccountTypes, useUpdateAccountType } from 'api'
import { useAccountTypePolicy } from 'hooks'
import { AccessDenied } from 'components'
import { Button, Loader } from 'components/ui'
import SettingsSecurityLayout from '../layouts/SettingsSecurityLayout'
import { SettingsAccountTypeList } from '../components/SettingsAccountTypeList'

export const SettingsAccountTypeListContainer = () => {
    const accountTypePolicy = useAccountTypePolicy()
    const [filters, setFilters] = useReducer(listReducer, {
        page: 1,
        length: DEFAULT_LIST_PER_PAGE,
    })

    const accountTypes = useGetAccountTypes(filters, {
        enabled: accountTypePolicy.canIndex,
    })

    const { mutate: updateAccountType } = useUpdateAccountType()

    return (
        <SettingsSecurityLayout
            title="Bezpieczeństwo"
            actions={
                accountTypePolicy.canCreate && (
                    <>
                        <Button
                            as="link"
                            to="/settings/security/account-types/add"
                            variant="primary"
                            iconRight={<PlusIcon />}
                        >
                            Dodaj
                        </Button>
                    </>
                )
            }
        >
            <>
                {!accountTypePolicy.canIndex && (
                    <AccessDenied message="Nie masz uprawnień" />
                )}
                {accountTypes.isLoading && <Loader />}
                {accountTypes.isError && (
                    <div>{accountTypes.error.message}</div>
                )}
                {accountTypes.isSuccess && (
                    <SettingsAccountTypeList
                        changeActive={(id, active) =>
                            updateAccountType(
                                { id, active },
                                { onSuccess: () => accountTypes.refetch() }
                            )
                        }
                        data={accountTypes.data}
                        setFilters={setFilters}
                    />
                )}
            </>
        </SettingsSecurityLayout>
    )
}
