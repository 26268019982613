import React from 'react'
import { Formik, Form, FieldArray } from 'formik'
import { PlusIcon, XIcon } from '@heroicons/react/solid'

import {
    ActionBar,
    DropdownField,
    DropdownItem,
    TextAreaField,
    TextField,
} from 'components/forms'
import { Button, InputText, Label, PhoneInput, Toggle } from 'components/ui'
import AvatarUploader from 'components/AvatarUploader'

import type { FormSubmitFn, Role, Specialization } from 'types'
import type { OperatingRoom, ResponseData, UserResponse } from 'api/types'
import type { UserForm } from 'types/UserForm'
import type { UploaderState } from 'components/FileUploader'

const SettingsUserForm: React.FC<{
    user?: ResponseData<UserResponse>
    roles: Role[]
    specializations: Specialization[]
    rooms: OperatingRoom[]
    handleSubmit: FormSubmitFn<UserForm>
}> = ({ user, roles, specializations, rooms, handleSubmit }) => {
    const initialValues: UserForm = {
        avatar: undefined,
        first_name: user?.data.first_name || '',
        last_name: user?.data.last_name || '',
        email: user?.data.email || '',
        pesel: user?.data.pesel || '',
        phone: user?.data.phone || '',
        address: user?.data.address || '',
        license: user?.data.license || '',
        role: user?.data.role || undefined,
        specializations: user?.data.specializations || [],
        _specialization: undefined,
        active: user ? user.data.active : true,
        blocked: user ? user.data.blocked : false,
        pin: user?.data.pin || '',
        _isTablet: user?.data.operating_room_id
            ? !!rooms.find((item) => item.id === user.data.operating_room_id)
            : false,
        operating_room: user?.data.operating_room_id
            ? rooms.find((item) => item.id === user.data.operating_room_id) ||
              undefined
            : undefined,
    }

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ values, setFieldValue, errors }) => (
                <Form>
                    <div className="rounded-md bg-white">
                        <div className="divide-gray-200 divide-y">
                            <div className="mb-16 pt-12 px-6">
                                <div className="text-gray-700 mb-16">
                                    <div className="grid grid-cols-2 text-gray-700 divide-gray-200 divide-x">
                                        <div className="col-span-1 pr-8">
                                            <div className="text-lg leading-6 font-medium text-gray-900">
                                                Profil
                                            </div>
                                            <div className="divide-gray-200 divide-y">
                                                <section className="pb-8">
                                                    <div className="mt-6">
                                                        <Label>
                                                            Zdjęcie profilowe
                                                        </Label>
                                                        <div className="mt-6">
                                                            <AvatarUploader
                                                                url={
                                                                    user?.data
                                                                        .avatar_url
                                                                }
                                                                handleUploadSuccess={(
                                                                    value: UploaderState
                                                                ) =>
                                                                    setFieldValue(
                                                                        'avatar',
                                                                        value
                                                                            .addedFile
                                                                            .id
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="mt-6">
                                                        <TextField
                                                            labelText="Imię"
                                                            name="first_name"
                                                        />
                                                    </div>
                                                    <div className="mt-6">
                                                        <TextField
                                                            labelText="Nazwisko"
                                                            name="last_name"
                                                        />
                                                    </div>
                                                    <div className="mt-6">
                                                        <TextField
                                                            labelText="PESEL"
                                                            name="pesel"
                                                        />
                                                    </div>
                                                    <div className="mt-6">
                                                        <TextField
                                                            labelText="E-mail"
                                                            name="email"
                                                        />
                                                    </div>
                                                    <div className="mt-6">
                                                        <PhoneInput
                                                            label="Telefon"
                                                            onChange={(value) =>
                                                                setFieldValue(
                                                                    'phone',
                                                                    value
                                                                )
                                                            }
                                                            value={values.phone}
                                                            error={errors.phone}
                                                        />
                                                    </div>
                                                    <div className="mt-6">
                                                        <TextAreaField
                                                            labelText="Adres"
                                                            name="address"
                                                            rows={3}
                                                        />
                                                    </div>
                                                    <div className="mt-6">
                                                        <TextField
                                                            labelText="Numer prawa wykonania zawodu"
                                                            name="license"
                                                        />
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                        <div className="col-span-1 pl-8">
                                            <div className="text-lg leading-6 font-medium text-gray-900">
                                                Uprawnienia
                                            </div>
                                            <section className="pb-8">
                                                <div className="mt-6">
                                                    <DropdownField
                                                        label="Specjalizacja"
                                                        name="_specialization"
                                                        items={specializations}
                                                    />
                                                </div>
                                                <div className="mt-6">
                                                    <FieldArray
                                                        name="specializations"
                                                        render={({
                                                            push,
                                                            remove,
                                                            form: {
                                                                values,
                                                                setFieldValue,
                                                            },
                                                        }) => (
                                                            <>
                                                                <Button
                                                                    variant="tertiary"
                                                                    iconRight={
                                                                        <PlusIcon />
                                                                    }
                                                                    onClick={() => {
                                                                        push(
                                                                            values._specialization
                                                                        )
                                                                        setFieldValue(
                                                                            '_specialization',
                                                                            undefined
                                                                        )
                                                                    }}
                                                                    disabled={
                                                                        !values._specialization ||
                                                                        !!(
                                                                            values.specializations as DropdownItem[]
                                                                        ).find(
                                                                            (
                                                                                i
                                                                            ) =>
                                                                                i.id ===
                                                                                values
                                                                                    ._specialization
                                                                                    .id
                                                                        )
                                                                    }
                                                                >
                                                                    Dodaj
                                                                    specjalizację
                                                                </Button>
                                                                <div className="mt-4">
                                                                    {(
                                                                        values.specializations as DropdownItem[]
                                                                    ).map(
                                                                        (
                                                                            item,
                                                                            index
                                                                        ) => (
                                                                            <div
                                                                                key={
                                                                                    item.id
                                                                                }
                                                                                className="mb-0.5 p-2 flex items-center justify-between bg-gray-100 text-sm text-gray-900 leading-5 font-normal"
                                                                            >
                                                                                <span className="flex-grow truncate">
                                                                                    {
                                                                                        item.name
                                                                                    }
                                                                                </span>
                                                                                <div
                                                                                    className="ml-2 flex-shrink-0 flex cursor-pointer"
                                                                                    onClick={() =>
                                                                                        remove(
                                                                                            index
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <span className="text-gray-500 hover:text-gray-700">
                                                                                        <XIcon className="h-5 w-5" />
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </div>
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                                <div className="mt-6">
                                                    <DropdownField
                                                        label="Rola"
                                                        name="role"
                                                        items={roles}
                                                    />
                                                </div>
                                                <div className="mt-6">
                                                    <Label>Typ konta</Label>
                                                    <InputText
                                                        value={
                                                            roles.find(
                                                                (role) =>
                                                                    values.role
                                                                        ?.id ===
                                                                    role.id
                                                            )?.account_type
                                                                ?.name || '-'
                                                        }
                                                        disabled
                                                    />
                                                </div>
                                            </section>
                                            <div className="text-lg leading-6 font-medium text-gray-900">
                                                Status użytkownika
                                            </div>
                                            <section className="pb-8">
                                                <div className="mt-6">
                                                    <div className="grid grid-cols-3 gap-x-4 gap-y-8">
                                                        <div className="col-span-1">
                                                            <div className="flex items-center">
                                                                <Toggle
                                                                    checked={
                                                                        values.active
                                                                    }
                                                                    handleChange={() =>
                                                                        setFieldValue(
                                                                            'active',
                                                                            !values.active
                                                                        )
                                                                    }
                                                                />
                                                                <Label className="pl-3 -mb-0">
                                                                    Aktywny
                                                                </Label>
                                                            </div>
                                                        </div>
                                                        <div className="col-span-1">
                                                            <div className="flex items-center">
                                                                <Toggle
                                                                    checked={
                                                                        values._isTablet
                                                                    }
                                                                    handleChange={() => {
                                                                        if (
                                                                            values._isTablet
                                                                        ) {
                                                                            setFieldValue(
                                                                                'operating_room',
                                                                                undefined
                                                                            )
                                                                        }

                                                                        setFieldValue(
                                                                            '_isTablet',
                                                                            !values._isTablet
                                                                        )
                                                                    }}
                                                                />
                                                                <Label className="pl-3 -mb-0">
                                                                    Tablet
                                                                </Label>
                                                            </div>
                                                        </div>
                                                        <div className="col-span-1">
                                                            <div className="flex items-center">
                                                                <Toggle
                                                                    checked={
                                                                        values.blocked
                                                                    }
                                                                    handleChange={(
                                                                        value
                                                                    ) => {
                                                                        setFieldValue(
                                                                            'blocked',
                                                                            value
                                                                        )
                                                                    }}
                                                                />
                                                                <Label className="pl-3 -mb-0">
                                                                    Zablokowany
                                                                </Label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {values._isTablet && (
                                                    <div className="mt-6">
                                                        <Label>
                                                            Wybierz salę
                                                            operacyjną
                                                        </Label>
                                                        <DropdownField
                                                            name="operating_room"
                                                            items={rooms}
                                                        />
                                                    </div>
                                                )}
                                            </section>
                                            {!!user && (
                                                <>
                                                    <div className="text-lg leading-6 font-medium text-gray-900">
                                                        Pin użytkownika
                                                    </div>
                                                    <section className="pb-8">
                                                        <div className="mt-6">
                                                            <div className="grid grid-cols-6 gap-x-8 gap-y-8">
                                                                <div className="col-span-1">
                                                                    <InputText
                                                                        className="pr-0"
                                                                        disabled
                                                                        value={
                                                                            values.pin
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mx-6 py-12">
                                <ActionBar
                                    rightSide={({ isSubmitting }) => (
                                        <>
                                            <Button
                                                as="link"
                                                to={
                                                    user
                                                        ? `/settings/users/${user.data.id}`
                                                        : '/settings/users'
                                                }
                                            >
                                                Anuluj
                                            </Button>
                                            <Button
                                                type="submit"
                                                variant="primary"
                                                className="ml-3"
                                                loading={isSubmitting}
                                                iconRight={<PlusIcon />}
                                            >
                                                Zapisz
                                            </Button>
                                        </>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default SettingsUserForm
