import axios from 'axios'

import { LOCAL_STORAGE_AUTH_TOKEN_KEY } from 'constants/index'
import { ResponseError } from './types/axios'

const { REACT_APP_API_BASE_URL } = process.env

if (!REACT_APP_API_BASE_URL) {
    throw new Error('Specify REACT_APP_API_BASE_URL in your .env file')
}

const axiosInstance = axios.create()

axiosInstance.defaults.baseURL = REACT_APP_API_BASE_URL
axiosInstance.defaults.headers.common['Accept'] = 'application/json'
axiosInstance.defaults.headers.post['Content-Type'] = 'application/json'

axiosInstance.interceptors.request.use((config) => {
    const headers: { [key: string]: string } = {}
    const authToken = localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN_KEY)

    if (authToken) {
        headers.Authorization = `Bearer ${authToken}`
    }

    config.headers = {
        ...config.headers,
        ...headers,
    }

    return config
})

axiosInstance.interceptors.response.use(
    (response) => {
        if (
            [
                'application/pdf',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            ].some((type) => type === response.headers['content-type'])
        ) {
            return Promise.resolve(response)
        }

        return response.data
    },
    (error) => {
        const enhancedError: ResponseError = {
            ...error,
            message: 'Something went wrong',
            errors: {},
        }

        if (error.response) {
            const { data, status } = error.response

            enhancedError.statusCode = status
            enhancedError.data = data.data

            if (status === 401) {
                localStorage.removeItem(LOCAL_STORAGE_AUTH_TOKEN_KEY)
            }

            if (data.message) {
                enhancedError.message = data.message
            }

            enhancedError.errors = Object.keys(data.errors || {}).reduce(
                (acc, fieldName) => ({
                    ...acc,
                    [fieldName]: data.errors[fieldName][0],
                }),
                {}
            )
        }

        return Promise.reject(enhancedError)
    }
)

export default axiosInstance
