import React, { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'

import {
    useGetOperation,
    useGetOperationTasks,
    useUpdateOperation,
    useUpdateOperationTask,
    useDeleteOperationTask,
} from 'api'
import { MODAL_TRANSITION_LEAVE_DURATION } from 'components'
import { MESSAGES, OPERATION_REJECT_REASONS } from 'constants/index'
import {
    useModal,
    useCaregiverChannel,
    useChildToggle,
    useNotification,
} from 'hooks'
import { formatDateNoYear, formatTime, queryDataTaskUpdater } from 'helpers'
import { Loader, SlideOver } from 'components/ui'
import OperationCard from 'components/OperationCard'
import OperationTask from 'containers/OperationTask'
import OperationCommentForm from 'containers/OperationCommentForm'
import OperationAdmissionDateModal from 'components/OperationAdmissionDateModal'
import EmployeeAssignModal from 'components/EmployeeAssignModal'
import PatientDietModal from 'components/PatientDietModal'
import ConfirmDeleteModal from 'components/ConfirmDeleteModal'
import HistoryModal from '../components/HistoryModal'
import OperationSMSList from 'components/OperationSMSList'
import CaregiverSurgeryDescriptionFormModal from 'components/CaregiverSurgeryDescriptionFormModal'
import CaregiverSurgeryRoomFormModal from 'components/CaregiverSurgeryRoomFormModal'

import type {
    User,
    FormSubmitFn,
    AdmissionDateModalForm,
    PatientDietForm,
    CancelDeleteModalForm,
    CaregiverSurgeryForm,
} from 'types'
import type { TaskStatusTransitionState } from 'components/TaskExpiredModal'
import UserAvatar from '../components/UserAvatar'

const OperationCardContainer: React.FC<{
    id?: number
    isEditButton?: boolean
    showComments?: boolean
    showSMSes?: boolean
    showRejections?: boolean
    onTaskChange?: () => void
    onClose?: () => void
}> = ({
    id,
    showComments = false,
    showSMSes = false,
    showRejections = false,
    isEditButton = false,
    onTaskChange,
    onClose,
}) => {
    const queryClient = useQueryClient()
    const showNotification = useNotification()
    const operation = useGetOperation(id)
    const tasks = useGetOperationTasks(id, { enabled: operation.isSuccess })
    const { mutate: update } = useUpdateOperation()
    const { mutate: updateTask } = useUpdateOperationTask()
    const { mutate: deleteTask } = useDeleteOperationTask()

    const [historyModalType, setHistoryModalType] = useState<
        string | undefined
    >()

    const { onCaregiverChannelEvent, handleOperationChange } =
        useCaregiverChannel()

    useEffect(() => {
        if (!isEditButton) return

        const { closeChannel } = onCaregiverChannelEvent(
            handleOperationChange(id)
        )

        return () => {
            closeChannel()
        }
        // eslint-disable-next-line
    }, [id])

    const modalCreateTask = useModal(false)
    const modalTask = useModal(false)
    const modalAssignEmployee = useModal(false)
    const modalAdmissionDate = useModal(false)
    const modalPatientDiet = useModal()
    const modalDescription = useModal()
    const modalRoom = useModal()
    const modalConfirmDelete = useModal<number>()
    const modalHistory = useModal(false)

    useEffect(() => {
        historyModalType && modalHistory.openModal()
    }, [historyModalType]) // eslint-disable-line

    const handleChildToggleChange = useChildToggle(id!, 'operation')
    const handleChangeAdmissionDate: FormSubmitFn<AdmissionDateModalForm> = (
        values,
        formikHelpers
    ) => {
        update(
            {
                id: id!,
                data: {
                    admission_date:
                        values._date +
                        (values._time ? ' ' + values._time.id : ''),
                    patient_informed: values.patient_informed,
                },
            },
            {
                onSuccess: async () => {
                    await queryClient.invalidateQueries(['operations'])
                    formikHelpers.setSubmitting(false)
                    modalAdmissionDate.closeModal()
                },
                onError: (error) => {
                    formikHelpers.setSubmitting(false)
                    formikHelpers.setErrors(error.errors)
                },
            }
        )
    }

    const handleAssignCaregiver: FormSubmitFn<{ user?: User }> = (
        values,
        formikHelpers
    ) => {
        update(
            {
                id: id!,
                data: { user: values.user?.id || null },
            },
            {
                onSuccess: async () => {
                    await queryClient.invalidateQueries(['operations', id])
                    formikHelpers.setSubmitting(false)
                    modalAssignEmployee.closeModal()
                },
                onError: (error) => {
                    formikHelpers.setSubmitting(false)
                    formikHelpers.setErrors(error.errors)
                },
            }
        )
    }

    const handleOpenTask = (id: number) => {
        modalTask.setState(id)
        modalTask.openModal()
    }

    const updateTaskStatus = (state: TaskStatusTransitionState) => {
        queryClient.setQueryData(
            ['operation-tasks', 'operation', id],
            queryDataTaskUpdater(state.item.id, {
                status: state.nextStatus,
            })
        )

        return updateTask(
            { id: state.item.id, status: state.nextStatus },
            {
                onSuccess: async () => {
                    showNotification({
                        content: MESSAGES.TASK_STATUS_UPDATED_SUCCESS,
                        type: 'success',
                    })
                    await queryClient.invalidateQueries('operation-tasks')
                    await queryClient.invalidateQueries('operations')
                },
                onError: () => {
                    showNotification({
                        content: MESSAGES.TASK_STATUS_UPDATE_FAILED,
                        type: 'danger',
                    })
                },
            }
        )
    }

    const handleUpdateTaskStatus = (state: TaskStatusTransitionState) => {
        updateTaskStatus(state)
    }

    const handleSubmitPatientDietForm: FormSubmitFn<PatientDietForm> = (
        data,
        formikHelpers
    ) => {
        update(
            { id: id!, data },
            {
                onSuccess: async () => {
                    await queryClient.invalidateQueries(['operations', id])
                    formikHelpers.setSubmitting(false)
                    showNotification({
                        content: MESSAGES.SURGERY_DIET_INFO_UPDATED,
                        type: 'success',
                    })
                    modalPatientDiet.closeModal()
                },
                onError: (error) => {
                    formikHelpers.setSubmitting(false)
                    formikHelpers.setErrors(error.errors)
                },
            }
        )
    }

    const handleRoomSubmit: FormSubmitFn<CaregiverSurgeryForm> = (
        data,
        formikHelpers
    ) => {
        if (!id) return

        update(
            {
                id,
                data,
            },
            {
                onSuccess: async () => {
                    await queryClient.invalidateQueries(['operations', id])
                    formikHelpers.setSubmitting(false)
                    showNotification({
                        content: 'Pokój został zaktualizowany',
                        type: 'success',
                    })
                    modalRoom.closeModal()
                },
                onError: (error) => {
                    formikHelpers.setSubmitting(false)
                    formikHelpers.setErrors(error.errors)
                },
            }
        )
    }

    const handleDescriptionSubmit: FormSubmitFn<CaregiverSurgeryForm> = (
        data,
        formikHelpers
    ) => {
        if (!id) return

        update(
            {
                id,
                data,
            },
            {
                onSuccess: async () => {
                    await queryClient.invalidateQueries(['operations', id])
                    showNotification({
                        content: 'Uwagi zostały zaktualizowane',
                        type: 'success',
                    })
                    formikHelpers.setSubmitting(false)
                    modalDescription.closeModal()
                },
                onError: (error) => {
                    formikHelpers.setSubmitting(false)
                    formikHelpers.setErrors(error.errors)
                },
            }
        )
    }

    const handleCaregiversCommentsSubmit: FormSubmitFn<CaregiverSurgeryForm> = (
        data,
        formikHelpers
    ) => {
        if (!id) return

        update(
            {
                id,
                data,
            },
            {
                onSuccess: async () => {
                    await queryClient.invalidateQueries(['operations', id])
                    showNotification({
                        content: 'Uwagi zostały zaktualizowane',
                        type: 'success',
                    })
                },
                onError: (error) => {
                    formikHelpers.setErrors(error.errors)
                },
                onSettled: () => {
                    formikHelpers.setSubmitting(false)
                },
            }
        )
    }

    const handleDelete = (id: number) => {
        modalConfirmDelete.setState(id)
        modalConfirmDelete.openModal()
    }

    const handleDeleteConfirmed: FormSubmitFn<CancelDeleteModalForm> = (
        values,
        formikHelpers
    ) => {
        deleteTask(values.id, {
            onSuccess: () => {
                formikHelpers.setSubmitting(false)
                showNotification({
                    content: 'Zadanie zostało usunięte',
                    type: 'success',
                })
                modalConfirmDelete.closeModal()
                setTimeout(
                    modalTask.closeModal,
                    MODAL_TRANSITION_LEAVE_DURATION
                )
                queryClient.invalidateQueries('operations')
                queryClient.invalidateQueries([
                    'operation-tasks',
                    'operation',
                    id,
                ])
            },

            onSettled: () => {
                formikHelpers.setSubmitting(false)
            },
        })
    }

    return (
        <>
            {operation.isLoading && <Loader />}
            {operation.isError && <p>{operation.error.message}</p>}
            {operation.isSuccess && (
                <>
                    <div className="divide-gray-200 divide-y">
                        <OperationCard
                            id={operation.data.data.id}
                            isEditButton={isEditButton}
                            data={operation.data.data}
                            tasks={tasks}
                            modalCreateTask={modalCreateTask}
                            modalTask={modalTask}
                            modalAssignEmployee={modalAssignEmployee}
                            modalAdmissionDate={modalAdmissionDate}
                            modalPatientDiet={modalPatientDiet}
                            modalDescription={modalDescription}
                            modalRoom={modalRoom}
                            handleChildToggleChange={handleChildToggleChange}
                            handleOpenTask={handleOpenTask}
                            handleUpdateTaskStatus={handleUpdateTaskStatus}
                            handleCaregiversCommentsSubmit={
                                handleCaregiversCommentsSubmit
                            }
                            setHistoryModalType={setHistoryModalType}
                            onClose={onClose}
                        />
                        {showRejections && (
                            <div className="mt-6">
                                <div className="pt-4 text-lg font-medium leading-6 text-gray-900">
                                    Przyczyny zrzucenia
                                </div>

                                <div className="flex flex-col gap-1 mt-2">
                                    {!operation.data.data.rejections ||
                                    operation.data.data.rejections.length === 0
                                        ? '-'
                                        : operation.data.data.rejections.map(
                                              (item) => (
                                                  <div
                                                      className="p-4 bg-gray-50 rounded-lg flex gap-4 items-center"
                                                      key={item.id}
                                                  >
                                                      <UserAvatar
                                                          variant="avatar"
                                                          data={item.causer}
                                                          size={8}
                                                      />
                                                      <div className="flex-1 flex flex-col">
                                                          <span className="text-sm leading-6 font-medium text-gray-900">
                                                              {
                                                                  item.causer
                                                                      .first_name
                                                              }{' '}
                                                              {
                                                                  item.causer
                                                                      .last_name
                                                              }
                                                          </span>
                                                          <span className="text-xs leading-3 font-normal text-gray-600">
                                                              <span className="font-medium text-gray-800">
                                                                  {
                                                                      OPERATION_REJECT_REASONS.find(
                                                                          (
                                                                              reason
                                                                          ) =>
                                                                              reason.type ===
                                                                              item
                                                                                  .reject_type
                                                                                  ?.type
                                                                      )?.label
                                                                  }
                                                              </span>
                                                              {item.reject_reason && (
                                                                  <>
                                                                      <br />
                                                                      {
                                                                          item.reject_reason
                                                                      }
                                                                  </>
                                                              )}
                                                          </span>
                                                      </div>
                                                      <div className="text-xs leading-none font-normal text-gray-400 self-start">
                                                          {!!item.created_at && (
                                                              <div className="text-xs leading-none font-normal text-gray-400 self-start">
                                                                  {formatDateNoYear(
                                                                      item.created_at
                                                                  )}
                                                                  <span className="text-gray-500 ml-2">
                                                                      {formatTime(
                                                                          item.created_at
                                                                      )}
                                                                  </span>
                                                              </div>
                                                          )}
                                                      </div>
                                                  </div>
                                              )
                                          )}
                                </div>
                            </div>
                        )}
                        {showComments && (
                            <div className="mt-6">
                                <OperationCommentForm
                                    operationId={id!}
                                    handleClickTaskId={(taskId) => {
                                        modalTask.setState(taskId)
                                        modalTask.openModal()
                                    }}
                                />
                            </div>
                        )}
                        {showSMSes && (
                            <div className="mt-6">
                                <OperationSMSList operationId={id!} />
                            </div>
                        )}
                    </div>
                    <SlideOver
                        isOpen={modalCreateTask.isOpen}
                        onClose={modalCreateTask.closeModal}
                        onClickOutside={modalCreateTask.closeModal}
                    >
                        <OperationTask
                            operation={operation.data.data}
                            handleClose={modalCreateTask.closeModal}
                        />
                    </SlideOver>
                    <SlideOver
                        isOpen={modalTask.isOpen}
                        onClose={modalTask.closeModal}
                        onClickOutside={
                            !modalConfirmDelete.isOpen
                                ? modalTask.closeModal
                                : undefined
                        }
                    >
                        <OperationTask
                            id={modalTask.getState() as number}
                            operation={operation.data.data}
                            onTaskChange={onTaskChange}
                            onDelete={handleDelete}
                            handleClose={() => {
                                modalTask.closeModal()
                            }}
                        />
                    </SlideOver>
                    <OperationAdmissionDateModal
                        modal={modalAdmissionDate}
                        data={operation.data.data}
                        handleSubmit={handleChangeAdmissionDate}
                    />
                    <EmployeeAssignModal
                        modal={modalAssignEmployee}
                        title="Przypisz opiekuna"
                        placeholder="Szukaj / wybierz opiekuna"
                        messageEmptyResult="Nie znaleziono opiekuna."
                        functions={['caregiver function']}
                        handleSubmit={handleAssignCaregiver}
                    />
                    <PatientDietModal
                        modal={modalPatientDiet}
                        data={operation.data.data}
                        onSubmit={handleSubmitPatientDietForm}
                    />
                    <CaregiverSurgeryRoomFormModal
                        modal={modalRoom}
                        data={operation.data.data}
                        onSubmit={handleRoomSubmit}
                    />
                    <CaregiverSurgeryDescriptionFormModal
                        modal={modalDescription}
                        data={operation.data.data}
                        onSubmit={handleDescriptionSubmit}
                    />
                    <HistoryModal
                        modal={modalHistory}
                        operationId={operation.data.data.id}
                        listTypes={[historyModalType || '']}
                        onClose={() => {
                            modalHistory.closeModal(() =>
                                setHistoryModalType(undefined)
                            )
                        }}
                    />
                    <ConfirmDeleteModal
                        title="Czy na pewno chcesz usunąć to zadanie?"
                        modal={modalConfirmDelete}
                        onSubmit={handleDeleteConfirmed}
                        onCancel={modalConfirmDelete.closeModal}
                    />
                </>
            )}
        </>
    )
}

export default OperationCardContainer
