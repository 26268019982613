import React, { useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import {
    useCreateRole,
    useGetAccountTypes,
    useGetPermissions,
    useGetRole,
    useUpdateRole,
} from 'api'
import { useNotification, useRolePolicyGroup } from 'hooks'
import { AccessDenied } from 'components'
import { Loader } from 'components/ui'
import SettingsRoleForm from 'components/SettingsRoleForm'

import type { UpdateRole } from 'api/types'
import type { FormSubmitFn } from 'types'
import type { IRoleForm } from 'types/RoleForm'

const SettingsRoleFormContainer: React.FC = () => {
    const { id } = useParams()
    const isEdit = !!id
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const rolePolicyGroup = useRolePolicyGroup()
    const showNotification = useNotification()
    const permissions = useGetPermissions(
        { pagination: false },
        {
            enabled: rolePolicyGroup.canAll,
        }
    )
    const role = useGetRole(Number(id), {
        enabled: rolePolicyGroup.canAll && !!id,
    })
    const accountTypes = useGetAccountTypes({
        active: true,
        sort_by: 'name',
        sort_direction: 'asc',
        length: 999,
        page: 1,
    })
    const { mutate: create } = useCreateRole()
    const { mutate: update } = useUpdateRole()

    const createOrUpdate = useMemo(
        () => (isEdit ? update : create),
        [create, update, isEdit]
    )

    const handleSubmit: FormSubmitFn<IRoleForm> = (values, formikHelpers) => {
        createOrUpdate(
            Object.assign(
                {},
                values,
                {
                    permissions: values.permissions.map((id) => ({ id })),
                },
                {
                    account_type_id: values.accountType?.id || null,
                },
                !!id ? { id: Number(id) } : {}
            ) as UpdateRole,
            {
                onSuccess: async () => {
                    await queryClient.invalidateQueries('roles')
                    formikHelpers.setSubmitting(false)
                    showNotification({
                        content: 'Zmiany zostały zapisane',
                        type: 'success',
                    })

                    if (!isEdit) {
                        navigate('/settings/roles')
                    }
                },
                onError: (error) => {
                    formikHelpers.setSubmitting(false)
                    formikHelpers.setErrors(
                        error.errors
                            ? {
                                  ...error.errors,
                                  accountType: error.errors.account_type_id,
                              }
                            : {}
                    )
                    showNotification({
                        content: 'Nie udało się zapisać zmian',
                        type: 'danger',
                    })
                },
            }
        )
    }

    if (!rolePolicyGroup.canAll) {
        return (
            <AccessDenied message="Nie masz uprawnień do zarządzania rolami" />
        )
    }

    if (permissions.isLoading || role.isLoading) {
        return <Loader />
    }

    if (role.isError) {
        return <div>{role.error.message}</div>
    }

    if (permissions.isError) {
        return <div>{permissions.error.message}</div>
    }

    return (
        <SettingsRoleForm
            data={role.data}
            permissions={permissions.data!.data}
            handleSubmit={handleSubmit}
            accountTypes={accountTypes.data?.data}
        />
    )
}

export default SettingsRoleFormContainer
