import React from 'react'

import styles from './InputText.module.scss'
import { getCxFromStyles } from 'helpers'
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline'

export type InputPasswordProps = {
    passwordVisibility: boolean
    onVisibilityChange: () => void
    handleChange?: React.ChangeEventHandler<HTMLInputElement>
    variant?: 'xs' | 'sm' | 'md' | 'lg'
    hasError?: boolean
    inputRef?: React.RefObject<HTMLInputElement>
} & React.InputHTMLAttributes<HTMLInputElement>

export default function InputPassword({
    type,
    disabled,
    autoComplete,
    hasError,
    handleChange,
    variant = 'md',
    className,
    inputRef,
    passwordVisibility,
    onVisibilityChange,
    ...rest
}: InputPasswordProps) {
    const cx = getCxFromStyles(styles)

    return (
        <div className="relative">
            <input
                type={type ?? 'text'}
                ref={inputRef}
                className={cx(
                    'root',
                    {
                        [styles.xs]: variant === 'xs',
                        [styles.sm]: variant === 'sm',
                        [styles.md]: variant === 'md',
                        [styles.lg]: variant === 'lg',
                        [styles.alignLeft]: true,
                        [styles.hasPostfix]: true,
                        [styles.error]: hasError,
                        [styles.disabled]: disabled,
                    },
                    className
                )}
                autoComplete={autoComplete || 'off'}
                disabled={disabled}
                onChange={handleChange}
                {...rest}
            />
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center z-10">
                <div
                    className="h-5 w-5 text-blue-600 cursor-pointer"
                    onClick={onVisibilityChange}
                >
                    {passwordVisibility ? (
                        <EyeIcon className="w-full h-full" aria-hidden="true" />
                    ) : (
                        <EyeOffIcon
                            className="w-full h-full text-blue-600"
                            aria-hidden="true"
                        />
                    )}
                </div>
            </div>
        </div>
    )
}
