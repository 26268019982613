import React from 'react'
import { Dialog } from '@headlessui/react'
import { twMerge } from 'tailwind-merge'
import { format } from 'date-fns'

import { IModal } from '../types'
import { Modal } from 'components'
import { useAcceptPasswordPolicy } from 'api'
import { Button } from './ui'

import { LockCyanIcon } from '../assets/icons'

import styles from './Modal.module.scss'

export const SecurityUpdateModal: React.FC<{
    modal: IModal
    date: string
    passwordRules: {
        length: number
        uppercaseLetters: number
        numbers: number
        specialCharacters: number
    }
}> = ({ modal, date, passwordRules }) => {
    const { mutate: acceptPasswordPolicy, isLoading: isUpdating } =
        useAcceptPasswordPolicy({
            onSuccess: () => {
                modal.closeModal()
            },
        })

    return (
        <Modal modal={modal} onClickOutside={() => null}>
            <div className={twMerge(styles.inner, 'p-6 my-2')}>
                <LockCyanIcon className="mx-auto mb-5" />
                <Dialog.Title as="h3" className={styles.title}>
                    Zmiana polityki bezpieczeństwa
                </Dialog.Title>
                <div className="flex flex-col gap-4 mt-4 text-center text-sm leading-5 font-normal text-gray-500">
                    <div>
                        W dniu{' '}
                        <span className="font-bold">
                            {format(new Date(date), 'dd.MM.yyyy')}
                        </span>{' '}
                        zostaną{' '}
                        <span className="font-bold">
                            wprowadzone nowe zasady bezpieczeństwa
                        </span>
                        , wymagające dostosowania hasła logowania do systemu.
                    </div>

                    <div>
                        Od tego dnia przy próbie zalogowania zostaniesz
                        poproszony
                        <br /> o{' '}
                        <span className="font-bold">
                            utworzenie nowego hasła
                        </span>
                        .
                    </div>

                    <div className="text-gray-500 text-sm leading-5 font-normal">
                        Nowe hasło będzie musiało mieć{' '}
                        <span className="font-bold">
                            długość min. {passwordRules.length}{' '}
                            {passwordRules.length === 1
                                ? 'znak'
                                : passwordRules.length < 5
                                ? 'znaki'
                                : 'znaków'}
                        </span>
                        <br />i zawierać przynajmniej:
                        <ul className="flex flex-col items-center mt-1">
                            {!!passwordRules.uppercaseLetters && (
                                <li className="inline-flex items-center">
                                    <span className="w-1 h-1 rounded-full inline-block bg-gray-500 mr-2" />
                                    {passwordRules.uppercaseLetters}{' '}
                                    {passwordRules.uppercaseLetters === 1
                                        ? 'dużą literę'
                                        : passwordRules.uppercaseLetters < 5
                                        ? 'duże litery'
                                        : 'dużych liter'}
                                </li>
                            )}
                            {!!passwordRules.numbers && (
                                <li className="inline-flex items-center">
                                    <span className="w-1 h-1 rounded-full inline-block bg-gray-500 mr-2" />
                                    {passwordRules.numbers}{' '}
                                    {passwordRules.numbers === 1
                                        ? 'cyfrę'
                                        : passwordRules.numbers < 5
                                        ? 'cyfry'
                                        : 'cyfr'}
                                </li>
                            )}
                            {!!passwordRules.specialCharacters && (
                                <li className="inline-flex items-center">
                                    <span className="w-1 h-1 rounded-full inline-block bg-gray-500 mr-2" />
                                    {passwordRules.specialCharacters}{' '}
                                    {passwordRules.specialCharacters === 1
                                        ? 'znak specjalny'
                                        : passwordRules.specialCharacters < 5
                                        ? 'znaki specjalne'
                                        : 'znaków specjalnych'}{' '}
                                    (np. !@#$%^&)
                                </li>
                            )}
                        </ul>
                    </div>
                </div>

                <Button
                    onClick={() => acceptPasswordPolicy()}
                    loading={isUpdating}
                    className="shadow-sm w-full rounded-md border border-gray-300 px-4 py-2 mt-6"
                >
                    Rozumiem
                </Button>
            </div>
        </Modal>
    )
}
