import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { Pagination } from 'components/ui'
import EmptyList from 'components/EmptyList'

import type { Role, ResponseList } from 'api/types'
import type { ListFilterAction } from 'helpers'

const SettingsRoleList: React.FC<{
    data: ResponseList<Role[]>
    setFilters: React.Dispatch<ListFilterAction>
}> = ({ data, setFilters }) => {
    return (
        <div className="bg-white p-2 rounded-lg">
            {data.meta.total > 0 && <Table data={data} />}
            {data.meta.total > 0 && (
                <Pagination
                    meta={data.meta}
                    handleChange={(page) =>
                        setFilters({ type: 'setPage', payload: page })
                    }
                />
            )}
            {data.meta.total === 0 && (
                <EmptyList
                    headline="Nic tu jeszcze nie ma"
                    message="Zacznij od stworzenia pierwszej roli."
                    to="/settings/roles/add"
                />
            )}
        </div>
    )
}

const Table: React.FC<{
    data: ResponseList<Role[]>
}> = ({ data }) => (
    <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50 text-xs text-gray-500 uppercase">
            <tr>
                <th
                    scope="col"
                    className="w-1 px-6 py-3 text-left font-medium tracking-wider"
                >
                    Id
                </th>
                <th
                    scope="col"
                    className="w-auto px-6 py-3 text-left font-medium tracking-wider"
                >
                    Nazwa
                </th>
                <th
                    scope="col"
                    className="w-1 px-6 py-3 text-left font-medium tracking-wider whitespace-nowrap"
                >
                    Typ konta
                </th>
                <th
                    scope="col"
                    className="w-1 px-6 py-3 text-left font-medium tracking-wider"
                >
                    Opcje
                </th>
            </tr>
        </thead>
        <tbody className="text-gray-500 text-sm bg-white divide-y divide-gray-200 leading-5">
            {data.data.map((item, index) => (
                <tr
                    key={item.id}
                    className={classNames({
                        'bg-gray-50': index % 2,
                    })}
                >
                    <td className="px-6 py-6">
                        <span>{item.id}</span>
                    </td>
                    <td className="px-6 py-6">
                        <span className="text-gray-900 font-medium">
                            {item.name}
                        </span>
                    </td>
                    <td className="px-6 py-6">
                        {item.account_type?.name || '-'}
                    </td>
                    <td className="px-6 py-1 text-right">
                        <span className="font-normal">
                            <Link
                                to={`/settings/roles/${item.id}/edit`}
                                className="inline-flex items-center px-3 py-2 shadow-sm text-xs leading-4 rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 cursor-pointer"
                            >
                                Zobacz
                            </Link>
                        </span>
                    </td>
                </tr>
            ))}
        </tbody>
    </table>
)

export default SettingsRoleList
