import React, { useMemo } from 'react'

import { useGetSecuritySettings, useUpdateSecuritySettings } from 'api'
import { useNotification, useSecurityGlobalPolicy } from 'hooks'
import { AccessDenied } from 'components'
import { Loader } from 'components/ui'
import SettingsSecurityLayout from 'layouts/SettingsSecurityLayout'
import { SettingsSecurityGeneral } from 'components/SettingsSecurityGeneral'

import type { FormSubmitFn, SecurityGeneralFormType } from 'types'
import type { SecuritySetting } from '../api/types'
import { SECURITY_SETTINGS } from '../constants'

export const SettingsSecurityGeneralContainer: React.FC = () => {
    const showNotification = useNotification()

    const securityGlobalPolicy = useSecurityGlobalPolicy()
    const securitySettings = useGetSecuritySettings({
        enabled: securityGlobalPolicy.canShow,
    })

    const { mutate: updateSecuritySettings } = useUpdateSecuritySettings()

    const handleSubmit: FormSubmitFn<SecurityGeneralFormType> = (
        values,
        formikHelpers
    ) => {
        updateSecuritySettings(
            Object.entries(values.settings).map(([key, dropdownData]) => ({
                name: key as SecuritySetting['name'],
                value: dropdownData.id.toString(),
            })),

            {
                onSuccess: async () => {
                    formikHelpers.setSubmitting(false)
                    showNotification({
                        content: 'Zmiany zostały zapisane',
                        type: 'success',
                    })

                    securitySettings.refetch()
                },
                onError: () => {
                    formikHelpers.setSubmitting(false)
                    showNotification({
                        content: 'Nie udało się zapisać zmian',
                        type: 'danger',
                    })
                },
            }
        )
    }

    const initialFormData: SecurityGeneralFormType = useMemo(() => {
        return {
            settings:
                securitySettings.data?.data.reduce((acc, item) => {
                    return {
                        ...acc,
                        [item.name]: SECURITY_SETTINGS[item.name].options.find(
                            (option) => option.id === item.value
                        ),
                    }
                }, {}) || {},
        }
    }, [securitySettings])

    return (
        <SettingsSecurityLayout title="Bezpieczeństwo">
            <>
                {!securityGlobalPolicy.canShow && (
                    <AccessDenied message="Nie masz uprawnień" />
                )}
                {securitySettings.isLoading && <Loader />}
                {securitySettings.isError && (
                    <div>{securitySettings.error.message}</div>
                )}
                {securitySettings.isSuccess && (
                    <SettingsSecurityGeneral
                        handleSubmit={handleSubmit}
                        initialFormData={initialFormData}
                    />
                )}
            </>
        </SettingsSecurityLayout>
    )
}
