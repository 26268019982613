import React, { CSSProperties } from 'react'
import { usePopperTooltip } from 'react-popper-tooltip'
import classNames from 'classnames'

import 'react-popper-tooltip/dist/styles.css'
import popperStyles from 'components/ui/Tooltip.module.scss'

export const Tooltip: React.FC<{
    tooltipText?: string
    children: React.ReactNode
    style?: CSSProperties
}> = ({ tooltipText, children, style }) => {
    const {
        getArrowProps,
        getTooltipProps,
        setTooltipRef,
        setTriggerRef,
        visible,
    } = usePopperTooltip({
        placement: 'top-start',
    })

    return (
        <div ref={setTriggerRef} className="flex-shrink-0 relative">
            {children}
            {visible && (
                <div
                    ref={setTooltipRef}
                    {...getTooltipProps({
                        className: classNames(
                            'tooltip-container',
                            popperStyles.tooltip
                        ),
                        style,
                    })}
                >
                    <div
                        {...getArrowProps({
                            className: classNames(
                                'tooltip-arrow',
                                popperStyles.arrow
                            ),
                        })}
                    />
                    {tooltipText}
                </div>
            )}
        </div>
    )
}
